.marketing-main-css {

  $tablehead: #b9d8f9;
  $tablebody: rgba(219, 233, 255, 0.25);
  $borderColor: rgba(0, 0, 0, 0.25);
  $lightBlue: rgba(219, 233, 255, 0.7);
  $fontFamily: DM Sans;
  
  .ql-toolbar {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  .table-striped>tbody>tr:nth-child(odd)>td,
  .table-striped>tbody>tr:nth-child(odd)>th {
    border-color: rgba(230, 237, 255, 0.8);

    border-color: #E6EDFF;
  }

  .table-striped>tbody>tr:nth-child(even)>td,
  .table-striped>tbody>tr:nth-child(even)>th {
    border-color: #FFFFFF;
    background-color: #FFFFFF;
  }

  .last>tbody>tr:nth-last-child(1)>td,
  .last>tbody>tr:nth-last-child(1)>th {
    border-color: #FFFFFF;
    background-color: $tablehead;
  }


  .collectionTable {


    table {
      // width: 60%;

      thead {
        background: $tablehead;
        text-align: left !important;

        th {
          padding: 12px;
          font-family: $fontFamily;

        }

        th:first-child {
          border-top-left-radius: 15px;
        }

        th:last-child {
          border-top-right-radius: 15px;
        }
      }

      tbody {
        background: $tablebody;
        border-bottom-right-radius: 15px;

        td {
          border-bottom: 0 !important;
          padding: 12px !important;
          font-size: 14px;
          color: black;

          span {
            font-weight: 400;
            font-family: $fontFamily;
          }
        }

        tr {
          // &:first-child {
          //   font-weight: normal;
          //   border-bottom: solid 1px $borderColor;
          //   height: 70px !important;
          //   font-family: $fontFamily;
          // }

          .table-icon {
            border: 0;
            padding: 0;
            background-color: transparent;

            &:hover {
              background-color: transparent;
            }
          }
        }

        tr:nth-last-child(1) {
          td:first-child {
            border-bottom-left-radius: 15px;
          }

          td:last-child {
            border-bottom-right-radius: 15px;
          }
        }
      }
    }
  }

  .collection-table {
    table {
      // width: 60%;

      thead {
        background: $tablehead;
        text-align: left !important;

        th {
          padding: 20px;
          font-family: $fontFamily;
        }

        th:first-child {
          border-top-left-radius: 15px;
        }

        th:last-child {
          border-top-right-radius: 15px;
        }
      }

      tbody {
        background: $tablebody;

        td {
          border-bottom: 0 !important;
          padding: 20px !important;
          font-size: 14px;
          color: black;

          span {
            font-weight: 400;
            font-family: $fontFamily;
          }
        }

        tr {
          &:first-child {
            font-weight: normal;
            border-bottom: solid 1px $borderColor;
            height: 70px !important;
            font-family: $fontFamily;
          }

          .table-icon {
            border: 0;
            padding: 0;
            background-color: transparent;

            &:hover {
              background-color: transparent;
            }
          }
        }

        // tr:nth-last-child(1) {
        //   td:first-child {
        //     border-bottom-left-radius: 15px;
        //   }

        //   td:last-child {
        //     border-bottom-right-radius: 15px;
        //   }
        // }
      }
    }
  }

  #comment {
    b {
      font-weight: 500;
      font-family: $fontFamily;
    }
  }

  .table-other {
    font-size: 12px !important;

    th {
      color: rgba(0, 0, 0, 0.65) !important;
      font-weight: normal !important;
      text-align: center;
    }

    .logs-th,
    .logs-td {
      width: 350px !important;
    }
  }

  .left-panel {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .heading {
      background: #1d3557;
      height: 35px;
      width: 100%;
      padding-left: 20px;
    }

    .first-heading {
      border-radius: 6px 6px 0 0;
      background: #1d3557;
      height: 35px;
      width: 100%;
      padding-left: 20px;
    }
  }

  .page-box {
    border: 1px solid gray;
    border-radius: 6px;
    padding: 8px;
  }

  .nthchild {
    tr:nth-child(odd) {
      background: white;
    }

    tr:nth-child(even) {
      background: grey;
    }
  }

  .form-control,
  .form-select {
    background-color: $lightBlue !important;
    border-radius: 6px;
    border: 0 !important;
    margin-top: 7px;
    padding: 10px;

    // max-width: 240px;
    // width: 100%;
    &:focus {
      outline: none !important;
      box-shadow: none;
    }

    .form-select-lg {
      line-height: 18px;
      margin-bottom: 10px;
      font-size: 11px;
    }
  }

  .totalcount {
    position: relative;
    border-radius: 16px;
    height: 450px;
    // padding-bottom: 250px;
  }

  .totalcount1 {
    color: #1d3557;
    margin-left: 10px;
  }

  .totalcount5 {
    position: absolute;
    left: 20px;
    right: 40px;
    bottom: 0px;
    top: 80px;
  }

  .budget {
    border-radius: 4px;
    border: 1px solid var(--purple-100, #f6f4fe);
    background: var(--purple-30,
        linear-gradient(180deg,
          rgba(235, 234, 252, 0.52) 0%,
          rgba(235, 234, 252, 0) 100%));
    height: 140px !important;
  }

  .sm-budget {
    border-radius: 4px;
    border: 1px solid var(--purple-100, #f6f4fe);
    background: var(--purple-30,
        linear-gradient(180deg,
          rgba(235, 234, 252, 0.52) 0%,
          rgba(235, 234, 252, 0) 100%));
    height: 100px !important;
  }

  .loanslider1 {
    border: 1px solid #0075ff3d;
    border-radius: 10px;
    border-right: none;
  }

  .shadow {
    border-radius: 16px;
    background: var(--base-white, #fff);

    /* light elevation */
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02),
      0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  }

  .shadow1 {
    border-radius: 8px;
    border: 1px solid var(--gray-200, #F2F2F2);
    background: var(--base-white, #FFF);
  }

  .totalloan5 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #F6CF7D;
  }

  .totalloan6 {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #697BE9;
  }

  .aggregatorHeader {
    color: #2B2F32;
    font-family: $fontFamily;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .tabsContainer {
    margin-top: 3rem;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // align-items: center;
    // flex-wrap: wrap;
    // flex-shrink: 0
    // border-bottom: 2px solid rgba(0, 0, 0, 0.10);
    ;
  }

  .aggDetailsTab {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 14.66%;
    padding: 16px 0px;
    margin-bottom: -2px;
    gap: 10px;
  }

  .runAggDetailsTab {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20.66%;
    padding: 16px 30px;
    margin-bottom: -2px;
    gap: 10px;
  }

  .tabText {
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    font-family: $fontFamily;
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

  }

  .tabText:hover {
    cursor: pointer;
  }

  .branch_Social_text {
    color: var(--text-color-80, rgba(0, 0, 0, 0.80));
    font-family: $fontFamily;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .leadBreakDown_Header {
    color: #000;
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .back_btn:hover {
    cursor: pointer;
  }

  .downloadReportText {
    color: var(--button-color, #267DFF);
    text-align: center;
    align-items: center;
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
  }

  .disbursedHeader {
    color: var(--text-color-80, rgba(0, 0, 0, 0.80));
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .disbursedAmountHeader {
    color: #000;
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }


  .filterHeaders {
    color: #000;
    text-align: start;
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  #startDate::placeholder {
    color: #959FBA;
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
  }

  .bulkCommTabsContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 75%;
    margin-top: 2rem;
    margin-bottom: 2rem,
  }

  .runBulkCommTabsContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 25%;
    margin-top: 0rem;
    margin-bottom: 2rem,
  }

  .bulkCommTabs {
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;


    padding: 4px 0px;
    cursor: pointer;
  }

  .csvCommTabs {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;


    padding: 4px 0px;
    cursor: pointer;
  }

  // .bulkCommTabs:hover {
  //   background-color: #1D3557;
  //   color: #FFFFFF;
  // }

  .bulkCommTabsText {
    color: var(--page-heading-color, #000);
    text-align: center;
    font-family: $fontFamily;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }



  .bulkCommHeader {
    color: #000;
    font-family: $fontFamily;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .bulkCommPreHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    div {
      color: var(--text-color-in-text-box-40, rgba(0, 0, 0, 0.40));
      font-family: $fontFamily;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

  }

  .inputTitle {
    color: rgba(0, 0, 0, 0.45);
    font-family: $fontFamily;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .dowpDownInputTitle {
    color: rgb(0, 0, 0);
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .sendBtn {
    margin-top: 0.8rem;
    width: 10%;
    border: none;
    border-radius: 12px;
    background: #2B78FF;
    padding: 13px;
    color: #FFF;
    /* Body bold */
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  .bulkCommSendBtn {
    margin-top: 0.8rem;
    width: 15%;
    border: none;
    border-radius: 12px;
    background: #006c67;
    // background: #2B78FF;
    padding: 13px;
    color: #FFF;
    /* Body bold */
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  #subject:focus {
    border: #FFFFFF;
  }

  .commModalBox {
    width: 50%;
    margin: auto;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.25);
    padding: 25px 30px;
  }

  .runBulkCommModalBox {
    width: 100%;
    margin: auto;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.25);
    padding: 25px 30px;
  }


  .cardtext {
    color: #1D3557;
    text-align: center;
    font-family: $fontFamily;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

  }


  #comment {
    font-family: $fontFamily;
  }

  .communiCateViaDropdownContainer {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .leadFountContainer {
    margin-top: 1rem;
    color: #FF772B;
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: start;
    padding: 15px 10px;
    border-radius: 12px;
    background: var(--delete-bg-color, #FFF2EA);
  }

  .btn-primary {
    color: #006c67;
    border-color: #006c67;
    // color: #2B78FF;
    // border-color: #2B78FF;
    background-color: white;
    border-radius: 12px;
    padding: 10px 20px;
  }

  .btn-primary:hover {
    color: white;
    border-color: #006c67;
    background-color: #006c67;
    // border-color: #2B78FF;
    // background-color: #2B78FF;
  }

  .width45 {
    width: 45%;
  }

  .width60 {
    width: 60%;
  }

  .width32 {
    width: 36%;
  }

  .btnWidth {
    margin-top: 0.8rem;
    // width: 15%;
    border: none;
    border-radius: 12px;
    background: #2B78FF;
    padding: 10px;
    color: #FFF;
    /* Body bold */
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    width: 55%;
  }

  .d-flex-column-start {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start
  }

  .hoverCusrsor {
    cursor: pointer;
  }

  // .table {

  //   background: linear-gradient(0deg, #E6EDFF, #E6EDFF),
  //     linear-gradient(0deg, rgba(185, 216, 249, 0.45), rgba(185, 216, 249, 0.45));
  //   border-bottom: 1px solid #E6EDFF;

  //   .head3 .tr1 .th1 {
  //     background: linear-gradient(0deg, #E6EDFF, #E6EDFF),
  //       linear-gradient(0deg, rgba(185, 216, 249, 0.45), rgba(185, 216, 249, 0.45));
  //   }

  //   tbody tr:nth-child(odd)td {
  //     background-color: #E6EDFF;
  //   }

  //   tbody tr:nth-child(even)td {
  //     background: linear-gradient(0deg, #E6EDFF, #E6EDFF),
  //       linear-gradient(0deg, #FFFFFF, #FFFFFF);

  //   }
  // }
  .contain {
    .row {
      .column1disbursement {
        box-shadow: 0px 2px 6px 4px #32475C05,
          0px 2px 9px 0px #32475C0A,
          0px 4px 4px 0px #00000040;
        background: #FFFFFF;
        border-radius: 16px;

        .h6 {
          font-size: 14px;
          font-weight: 700;
          color: rgba(29, 53, 87, 1);
        }
      }

      .column2disbursement {
        .row {
          .col {
            box-shadow: 0px 1px 10px 0px #00000012;
            border-radius: 8px;
            background: #FFFFFF;

            .h6 {
              font-size: 12px;
              font-weight: 500;
            }

            .h4 {
              font-size: 30px;
              text-align: center;
              margin-top: 40px;
              font-weight: 500;
            }

            .Square {
              margin-left: 77px;
              float: inherit;
              background-color: #E0F0E4;
              color: #377E36;
              font-size: 10px;
              font-weight: 400;
              width: 33px;
              height: 15px;
              border-radius: 4px;
            }

            .Square1 {
              position: relative;
              z-index: 0;
              top: -6.2rem;
              margin-left: 4.8rem;
              background-color: #E0F0E4;
              color: #377E36;
              font-size: 10px;
              font-weight: 400;
              width: 33px;
              height: 15px;
              border-radius: 4px;
            }

            .percentage {
              z-index: 0;
              font-size: 16px;
              font-weight: 700;
              position: relative;
              top: -6rem;
              margin-left: 4rem;
            }
          }
        }
      }
    }
  }

  .dailyUpdateStats {

    background-color: #E0F0E4;
    color: #377E36;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    font-size: 14px;
    font-weight: 400;
    padding: 0.1rem 0;
    border-radius: 4px;
  }

  .disbursementCard {
    margin-left: 0px;
    margin-right: 0px;
    justify-content: space-between;
  }


  @media only screen and (max-width:1000px) {
    .disbursementCard {
      margin-left: 0px;
      margin-right: 0px;
      justify-content: start;
    }

    .commModalBox {
      width: 60%;
      padding: 15px 15px;
      background-color: white;
    }

    .btnWidth {
      width: 60%;
    }
  }

  @media only screen and (max-width:925) {
    .commModalBox {
      width: 60%;
      padding: 15px 15px;
      background-color: white;
    }

    .btnWidth {
      width: 67%;
    }
  }

  .btnTable {
    width: 18%;

    // width: 15%;
    border: none;
    border-radius: 12px;
    background: #2B78FF;
    padding: 10px;
    color: #FFF;
    /* Body bold */
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }

  @media only screen and (max-width:820) {
    .disbursementCard {
      margin-left: 0px;
      margin-right: 0px;
      justify-content: space-between;
    }

    .commModalBox {
      width: 60%;
      padding: 15px 15px;
      background-color: white;
    }

    .btnWidth {
      width: 67%;
    }
  }

  .btnTable {
    width: 18%;

    // width: 15%;
    border: none;
    border-radius: 12px;
    background: #2B78FF;
    padding: 10px;
    color: #FFF;
    /* Body bold */
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }


  @media only screen and (max-width:800px) {
    .disbursementCard {
      margin-left: 0px;
      margin-right: 0px;
      justify-content: space-between;
    }

    .commModalBox {
      width: 60%;
      padding: 15px 15px;
      background-color: white;
    }

    .width45 {
      width: 100%;
    }

    .width60 {
      width: 100%;
    }

    .width32 {
      width: 100%;
      margin-top: 2rem;
    }

    .btnWidth {
      width: 72%;
    }
  }

  @media only screen and (max-width:700px) {
    .communiCateViaDropdownContainer {
      width: 100%;

    }

    .btnWidth {
      width: 90%;
    }

    .btnTable {
      width: 25%;
    }

    .commModalBox {
      width: 80%;
      padding: 15px 15px;
      background-color: white;
    }

    .bulkCommTabsContainer {
      width: 100%;
    }

    .runBulkCommTabsContainer {
      width: 100%;
    }

    .sendBtn {
      width: 25%;
    }

    .bulkCommSendBtn {
      width: 25%;
    }

    .collectionTable {
      overflow-x: scroll;
    }

  }

  .budget-card {
    border-radius: 4px;
    border: 1px solid var(--purple-100, #f6f4fe);
    background: var(--purple-30,
        linear-gradient(180deg,
          rgba(235, 234, 252, 0.52) 0%,
          rgba(235, 234, 252, 0) 100%));

  }

  @media only screen and (min-width:2500px) {
    .card-hight {
      min-height: 396px !important;
    }
  }

  .cards-shadow {
    border-radius: 8px;
    background: var(--system-colors-white-bg, #FFF);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.07);
    min-height: 260px;

  }

  .green-box {
    background-color: #E0F0E4;
    color: #377E36;
    font-size: 10px;
    font-weight: 400;
    border-radius: 4px;
    padding: 4px;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 0px;

  }


}

.recharts-wrapper {
  margin: 0 auto;
}

.new-card-market {
  border-radius: 8px;
  border: 1px solid #DDE1E6;
  background: #FFF;
  padding: 10px;
}


.recharts-tooltip-wrapper {

  .custom-tooltip-new {
    border-radius: 16px;
    background: linear-gradient(100deg, #FBFBFF 6.9%, rgba(255, 255, 255, 0.83) 84.24%);
    box-shadow: 0px 6px 35px 0px #D4D4D4;
    width: 260px;
    white-space: nowrap;

    .tooltipTitle {
      // margin: -10px;
      color: #666;
      top: 5px;
    }
  }
}

.grey-text {
  color: #999999 !important;

}

.sub-text {
  font-weight: 700;
  font-size: 15px;
}

.campaignTabactive {
  color: '#267DFF';
  font-weight: 700 !important;
  border-bottom: 3px solid #006c67;
  // border-bottom: 3px solid #267DFF;
  padding-bottom: 10px;
  font-size: 12px;
  cursor: pointer;
}

.campaignTab {
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700 !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.10);
  ;
  padding-bottom: 10px;
  font-size: 12px;
  cursor: pointer;
}

.leadsFound {
  padding: 10px;
  background: #FFF2EA;
  color: #FF772B;
  border-radius: 5px;
  margin-top: 20px;
  font-weight: 600;
}

.communication-tab-active-center {
  background: #006c67;
  font-weight: 600;
  cursor: pointer;
  padding: 14px;
  color: #fff;
  text-align: center;
}

.communication-tab-active-left {
  background: #006c67;
  font-weight: 600;
  cursor: pointer;
  padding: 14px;
  color: #fff;
  text-align: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

}

.communication-tab-active-right {
  background: #006c67;
  font-weight: 600;
  cursor: pointer;
  padding: 14px;
  color: #fff;
  text-align: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.communication-tab-center {
  background: #f2f2f9;
  padding: 14px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
}

.communication-tab-left {
  background: #f2f2f9;
  padding: 14px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

}

.communication-tab-right {
  background: #f2f2f9;
  padding: 14px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.sub-graph-div {

  min-height: 110px;
  padding: 20px;
}

.margin {
  margin-top: 10px;
}

.change-table {

  .table-header-cell,
  .table-data-cell {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    /* Adjust text alignment if needed */
  }

}
@media only screen and (min-width: 1900px) {
  .new-card-market{
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;

  }
  .shadow{
    width: 95%;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 30px;
  }
}